import {CARD_MOVE, CARD_ANIMATE} from './constants.js'

// Actions
// Follow FSA https://github.com/acdlite/flux-standard-action

export function moveCard (props) {
  return {
    type: CARD_MOVE,
    payload: props
  }
}

export function animateCard (props) {
  return {
    type: CARD_ANIMATE,
    payload: props
  }
}
