import cardList from './card-list'
import pile from './pile'
import card from './card'
import game from './game'

/*
const example = {
  cardList: [
    ... list of all cardId ...
  ],
  pile: {
    discard: [ ... list of cardId that are in this pile ... ],
    draw: [ ... list of cardId that are in this pile ... ]
  },
  card: {
    1: {
        id: 1,
        x: 0,
        ...
      }
  }
}
*/

export default function cardTricksApp (state = {}, action) {
  return {
    cardList: cardList(state.cardList, action),
    pile: pile(state.pile, action),
    card: card(state.card, action),
    game: game(state.game, action)
  }
}
