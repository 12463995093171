import {moveCard} from '../modules/card/actions.js'
const crdtrxCard = 'crdtrx-card'
const crdtrxPile = 'crdtrx-pile'
import {replaceCardList} from '../modules/card-list/actions.js'
import {getCardList, getCard} from '../store'
import {withinBounds} from '../utils'
let Card

// TODO: define areas for discard and draw piles?

export default TableComponent

function TableComponent (store, tableElement) {
  const self = this
  this.store = store
  this.tableElement = tableElement
  this.drawPileElement = document.getElementById('draw-pile')
  this.discardPileElement = document.getElementById('discard-pile')
  this.surfaceElement = tableElement.querySelector('[slot=surface]')
  Object.defineProperty(self, 'maxZ', {
    get: () => {
      return getCardList(self.store).reduce((maxZ, cardId) => {
        return Math.max(Number(getCard(self.store, cardId).z || 0), maxZ)
      }, 0)
    }
  })

  return {
    init: init.bind(this),
    resetCards: resetCards
  }

  function init () {
    // Listen for final xy positions of cards
    tableElement.addEventListener('crdtrx-card-xy', onCardXYEvent)

    return Promise.all([
      window.customElements.whenDefined(crdtrxCard).then(() => window.customElements.get(crdtrxCard)),
      window.customElements.whenDefined(crdtrxPile).then(() => window.customElements.get(crdtrxPile))
    ]).then(values => {
      Card = values.shift()
      // Pile = values.shift()
    }).then(() => {
      const update = UpdateFactory(this.store)
      this.store.subscribe(update)

      // The cardList is empty and nothing in localStorage
      if (!this.store.getState().cardList.length) {
        resetCards()
      }
    })
  }

  function UpdateFactory (store) {
    const cardFreezer = {}

    return function update () {
      // Update all cards in the cardList. Will create card elements if not already existing.
      const cardListFragment = document.createDocumentFragment()
      getCardList(store).forEach((cardId, index) => {
        let cardEl = document.getElementById(cardId)
        const newCard = getCard(store, cardId)
        const oldCard = cardFreezer[cardId]
        if (!cardEl) {
          const attrs = Object.assign({}, newCard)
          cardEl = createCard(attrs)
          cardFreezer[cardId] = Object.freeze(Object.assign({}, newCard))
          cardListFragment.appendChild(cardEl)
        } else {
          const changed = Object.keys(newCard).some((key) => {
            return newCard[key] !== oldCard[key]
          })
          if (changed) {
            cardFreezer[cardId] = Object.freeze(Object.assign({}, newCard))
            updateCard(cardEl, newCard)
          }
        }
      })
      self.surfaceElement.appendChild(cardListFragment)
    }
  }

  function updateCard (cardEl, attrs) {
    if (Object.keys(attrs).indexOf('friction') === -1) {
      cardEl.removeAttribute('friction')
    }
    Object.entries(attrs).forEach((item) => {
      cardEl.setAttribute(item[0], item[1])
    })
  }

  function createCard (attrs) {
    const cardEl = new Card()
    updateCard(cardEl, attrs)

    cardEl.addEventListener('transitionend', finished, true)
    function finished (ev) {
      cardEl.setAttribute('friction', true)
      cardEl.removeEventListener('transitionend', finished)
    }

    // Set flip card action
    cardEl.addEventListener('dblclick', function handleDoubleClick () {
      flipCard(cardEl)
    }, true)

    // Set z-index to top most z-index when mousedown and align the card vertically
    cardEl.addEventListener('mousedown', function handleMousedown () {
      self.store.dispatch(moveCard(Object.assign({}, zTopCard(attrs.z, cardEl.id), alignCard(cardEl.id))))
    }, true)

    return cardEl
  }

  function alignCard (cardId) {
    const newR = Math.round(Math.random() * 6) - 3
    return {
      id: cardId,
      r: String(newR)
    }
  }
  function zTopCard (initialZ, cardId) {
    let topZ = self.maxZ
    topZ = Number(initialZ) !== topZ ? topZ + 1 : topZ
    return {
      id: cardId,
      z: String(topZ)
    }
  }

  function flipCard (card) {
    self.store.dispatch(moveCard({
      id: card.id,
      side: card.getAttribute('side') === 'front' ? 'back' : 'front'
    }))
  }

  function onCardXYEvent (ev) {
    const card = Object.assign({
      width: ev.target.width,
      height: ev.target.height
    }, getCard(self.store, ev.target.id), ev.detail)

    if (!card.isAnimating) {
      if (withinBounds(card, self.discardPileElement.dataset)) {
        card.pile = self.discardPileElement.id
      } else if (withinBounds(card, self.drawPileElement.dataset)) {
        card.pile = self.drawPileElement.id
      } else {
        card.pile = self.tableElement.id
      }

      self.store.dispatch(moveCard(card))
    }
  }

  function resetCards () {
    // Create all the cards and place them in the draw pile
    const cardNames = ['01c', '01d', '01h', '01s', '02c', '02d', '02h', '02s', '03c', '03d', '03h', '03s', '04c', '04d', '04h', '04s', '05c', '05d', '05h', '05s', '06c', '06d', '06h', '06s', '07c', '07d', '07h', '07s', '08c', '08d', '08h', '08s', '09c', '09d', '09h', '09s', '10c', '10d', '10h', '10s', '11c', '11d', '11h', '11s', '12c', '12d', '12h', '12s', '13c', '13d', '13h', '13s']
    window.customElements.whenDefined(crdtrxPile).then(() => window.customElements.get(crdtrxPile))
      .then((Pile) => {
        const initialList = []
        Pile.randomListOfNumbers(cardNames.length).reduce((list, value, index) => {
          const name = cardNames[value]
          const pos = self.drawPileElement.offset(self.drawPileElement.position(index))
          Object.keys(pos).forEach((key) => {
            pos[key] = String(pos[key])
          })
          const card = Object.assign({
            id: `card-${value}`,
            name: name,
            back: 'back101',
            side: 'back',
            z: String(index),
            friction: 'true',
            pile: self.drawPileElement.id,
            container: tableElement.id
          }, pos)

          list.push(card)
          return list
        }, initialList)

        self.store.dispatch(replaceCardList(initialList))
      })
  }
}
