export function getCardList (store) {
  return store.getState().cardList
}

export function getCardListPile (store, pileId) {
  return store.getState().pile[pileId]
}

export function getCard (store, cardId) {
  return store.getState().card[cardId]
}
