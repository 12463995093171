import {CARD_ADD, CARDLIST_REPLACE} from './constants.js'

// Actions
// Follow FSA https://github.com/acdlite/flux-standard-action

export function addCard (props) {
  return {
    type: CARD_ADD,
    payload: props
  }
}

export function replaceCardList (list) {
  return {
    type: CARDLIST_REPLACE,
    payload: list
  }
}
