import {CARD_ADD, CARDLIST_REPLACE} from './constants.js'
// import card from '../card'

const initialState = []

export default cardList

function cardList (state = initialState, action) {
  switch (action.type) {
    case CARDLIST_REPLACE:
      const list = action.payload.map((item) => {
        return item.id
      })
      return list
    case CARD_ADD:
      return state.concat([action.payload.id])
    /*
    case CARD_MOVE:
      return state.map((item) => {
        if (item.id === action.payload.id) {
          return card(item, action)
        } else {
          return item
        }
      })
      */
    default:
      return state
  }
}
