import {moveCard, animateCard} from '../modules/card/actions.js'
import {getCardListPile, getCard} from '../store'

const crdtrxCard = 'crdtrx-card'
const crdtrxPile = 'crdtrx-pile'
// let Card
// let Pile

export default PileComponent

function PileComponent (store, pileElement) {
  const self = this
  self.pileId = pileElement.id
  self.x = pileElement.dataset.x
  self.y = pileElement.dataset.y
  self.width = pileElement.dataset.width
  self.height = pileElement.dataset.height
  this.store = store

  pileElement.addEventListener('crdtrx-pile-shuffle', shuffle, true)

  // Listen for the stack event on a pile
  pileElement.addEventListener('crdtrx-pile-stack', stack.bind(this), true)

  return {
    init: init.bind(this),
    id: self.pileId,
    shuffle: shuffleAllCards,
    stack: stackAllCards,
    flipUp: flipAllCardsUp,
    flipDown: flipAllCardsDown
  }

  function init () {
    pileElement.style.left = `${self.x}px`
    pileElement.style.top = `${self.y}px`
    pileElement.querySelector('[slot="area"]').style.width = `${self.width}px`
    pileElement.querySelector('[slot="area"]').style.height = `${self.height}px`
    // return Promise.all([
    //   window.customElements.whenDefined(crdtrxCard).then(() => window.customElements.get(crdtrxCard)),
    //   window.customElements.whenDefined(crdtrxPile).then(() => window.customElements.get(crdtrxPile))
    // ]).then(values => {
    //   // Card = values.shift()
    //   // Pile = values.shift()
    // }).then(() => {
    //   this.store.subscribe(update.bind(this))
    // })
  }

  function update () {
  }

  // Call the shuffle method with the count of random integers that will be added to the event.detail.
  function shuffleAllCards () {
    const list = getCardListPile(self.store, self.pileId)
    if (list && list.length) {
      pileElement.shuffle(list.length)
    }
  }

  // Call the stack method with the count of items that will be added to the event.detail.
  function stackAllCards () {
    const list = getCardListPile(self.store, self.pileId)
    if (list && list.length) {
      pileElement.stack(list.length)
    }
  }

  function flipAllCardsUp () {
    const list = getCardListPile(self.store, self.pileId)
    if (list && list.length) {
      list.forEach(flipCardUp)
    }
  }

  function flipAllCardsDown () {
    const list = getCardListPile(self.store, self.pileId)
    if (list && list.length) {
      list.forEach(flipCardDown)
    }
  }

  function flipCardUp (cardId) {
    self.store.dispatch(moveCard({
      id: cardId,
      side: 'front'
    }))
  }
  function flipCardDown (cardId) {
    self.store.dispatch(moveCard({
      id: cardId,
      side: 'back'
    }))
  }

  // Listen for the shuffle event on a pile
  function shuffle (ev) {
    // list contains the list of random integers
    const list = ev.detail.list

    // const topZ = self.maxZ

    getCardListPile(self.store, self.pileId).forEach((cardId, index) => {
      const card = getCard(self.store, cardId)
      const z = list[index]
      const pos = pileElement.offset(pileElement.position(index))

      // Shift all
      self.store.dispatch(animateCard({
        id: cardId,
        isAnimating: true,
        x: String(Number(card.x) + ((80 + Math.round(card.z * 0.4)) * (Math.round(Math.random() * 1) ? 1 : -1)))
      }))
      window.setTimeout(shifted, 1000)

      function shifted (ev) {
        // One by one move a random one back
        window.setTimeout(() => {
          self.store.dispatch(animateCard({
            id: cardId,
            x: String(pos.x),
            y: String(pos.y),
            z: String(z)
          }))

          window.setTimeout(finished, 1000)
          function finished (ev) {
            // Add impetus back in
            self.store.dispatch(moveCard({
              id: cardId,
              isAnimating: false,
              friction: 'true'
            }))
          }
        }, ((index + 1) * 50) + 100)
      }
    })
  }

  function stack (ev) {
    // list contains the list of positions
    const list = ev.detail.list
    getCardListPile(self.store, self.pileId).forEach((cardId, index) => {
      const cardEl = document.getElementById(cardId)
      const z = Math.min(Number(getCard(self.store, cardId).z) || 0, list.length - 1)
      const pos = pileElement.offset(list[z])

      // One by one shift to new stack position
      window.setTimeout(() => {
        cardEl.addEventListener('transitionend', finished, true)
        const timeoutId = window.setTimeout(finished, 400)

        // Stringify and animate only the values returned
        Object.keys(pos).forEach((key) => {
          pos[key] = String(pos[key])
        })
        this.store.dispatch(animateCard(Object.assign({id: cardId}, pos)))

        function finished (ev) {
          window.clearTimeout(timeoutId)
          // Add impetus back in
          self.store.dispatch(moveCard({
            id: cardId,
            friction: 'true'
          }))
          cardEl.removeEventListener('transitionend', finished)
        }
      }, (z * 50) + 100)
    })
  }
}
