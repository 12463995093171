import './app.css'
import debounce from 'debounce'
import {startGame} from './modules/game/actions.js'
import configureStore from './store'
import {saveState} from './utils'

import {TableComponent, PileComponent} from './components'

const crdtrxPile = 'crdtrx-pile'
const store = configureStore()

const appElement = document.getElementById('card-tricks-app')
const tableElement = document.getElementById('example-card-tricks-table')
const drawPileElement = document.getElementById('draw-pile')
const discardPileElement = document.getElementById('discard-pile')

const table = new TableComponent(store, tableElement)
const drawPile = new PileComponent(store, drawPileElement)
const discardPile = new PileComponent(store, discardPileElement)
const resetButton = document.getElementById('reset')

// Save the state of the game to localStorage on updates
store.subscribe(debounce(() => {
  saveState(store.getState())
}, 1000))

setActionButtons(drawPile)
setActionButtons(discardPile)

function setActionButtons (pileComponent) {
  document.querySelectorAll(`[data-pile=${pileComponent.id}]`).forEach(function (buttonElement) {
    switch (buttonElement.dataset.action) {
      case 'stack-all':
        buttonElement.addEventListener('click', pileComponent.stack)
        break
      case 'shuffle':
        buttonElement.addEventListener('click', pileComponent.shuffle)
        break
      case 'flip-up':
        buttonElement.addEventListener('click', pileComponent.flipUp)
        break
      case 'flip-down':
        buttonElement.addEventListener('click', pileComponent.flipDown)
        break
      default:
        console.warn('action not found', buttonElement.dataset.action)
    }
  })
}

table.init().then(() => {
  resetButton.addEventListener('click', table.resetCards)
  console.log(store.getState())
  drawPile.init()
  discardPile.init()
  // Trigger showing the cards
  store.dispatch(startGame())
})
