import {GAME_START} from './constants.js'

const initialState = {}

function game (state = initialState, action) {
  // placeholder for now
  switch (action.type) {
    case GAME_START:
      console.log('start game', action)
      return state
    default:
      return state
  }
}

export default game
