import {CARD_MOVE, CARD_ANIMATE} from './constants.js'
import {CARDLIST_REPLACE, CARD_ADD} from '../card-list/constants.js'

const initialState = {}

export default card

function card (state = initialState, action) {
  const card = {}

  switch (action.type) {
    case CARDLIST_REPLACE:
      return action.payload.reduce((cardList, item) => {
        cardList[item.id] = item
        return cardList
      }, {})
    case CARD_ADD:
    case CARD_MOVE:
      card[action.payload.id] = Object.assign({}, state[action.payload.id] || {}, action.payload)
      return Object.assign({}, state, card)
    case CARD_ANIMATE:
      const newCard = Object.assign({}, state[action.payload.id] || {}, action.payload)
      // Remove the friction attribute to allow for transition
      delete newCard.friction

      card[action.payload.id] = newCard
      return Object.assign({}, state, card)
    default:
      return state
  }
}
