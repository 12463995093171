const CARDS_ON_PLANE_STATE = 'CARDS_ON_PLANE_STATE'

export function loadState () {
  try {
    const serialized = window.localStorage.getItem(CARDS_ON_PLANE_STATE)
    if (serialized === null) {
      return undefined
    }
    return JSON.parse(serialized)
  } catch (error) {
    return undefined
  }
}

export function saveState (state) {
  try {
    const serialized = JSON.stringify(state)
    window.localStorage.setItem(CARDS_ON_PLANE_STATE, serialized)
  } catch (error) {
    console.error(error)
    console.error('Failed to save state', state)
  }
}
