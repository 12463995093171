import {CARD_MOVE, CARD_ANIMATE} from '../card/constants.js'
import {CARDLIST_REPLACE, CARD_ADD} from '../card-list/constants.js'
const initialState = {}

export default pile

function pile (state = initialState, action) {
  switch (action.type) {
    case CARDLIST_REPLACE:
      const all = {}
      // find all pile keys
      action.payload.forEach((card) => {
        if (card.pile) {
          const list = all[card.pile] || []
          if (list.length === 0) {
            all[card.pile] = list
          }
          list.push(card.id)
        }
      })
      console.log('pile', all)
      return all
    case CARD_ADD:
    case CARD_MOVE:
    case CARD_ANIMATE:
      const newState = Object.assign({}, state)
      const targetList = newState[action.payload.pile] || []
      // remove cardId from all piles if not already in target list
      if (action.payload.pile && targetList.indexOf(action.payload.id) === -1) {
        Object.keys(newState).forEach((key) => {
          const index = newState[key].indexOf(action.payload.id)
          if (index !== -1) {
            console.log('removing', action.payload.id)
            newState[key].splice(index, 1)
          }
        })

        // add cardId to the one pile if it is defined
        console.log('adding', action.payload.id, action.payload.pile)
        if (targetList.length === 0) {
          newState[action.payload.pile] = targetList
        }
        targetList.push(action.payload.id)
      }

      return newState

    default:
      return state
  }
}

