export default withinBounds

function num_points (x) {
  if (x < 0) { // none
    return -1
  }
  if (x > 0) { // segment
    return 1
  }
  return 0 // point
}

function withinBounds (box1, box2) {
  const bbox1 = {
    x1: Number(box1.x),
    y1: Number(box1.y),
    x2: Number(box1.x) + Number(box1.width),
    y2: Number(box1.y) + Number(box1.height)
  }
  const bbox2 = {
    x1: Number(box2.x),
    y1: Number(box2.y),
    x2: Number(box2.x) + Number(box2.width),
    y2: Number(box2.y) + Number(box2.height)
  }
  const nx = num_points(Math.min(bbox1.x2, bbox2.x2) - Math.max(bbox1.x1, bbox2.x1))
  const ny = num_points(Math.min(bbox1.y2, bbox2.y2) - Math.max(bbox1.y1, bbox2.y1))
  if (nx === -1 || ny === -1) {
    return false
  }
  if (nx === 0 || ny === 0) {
    return false
  }
  return true
}
